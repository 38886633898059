/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'residents-arrow': {
    width: 13,
    height: 14,
    viewBox: '0 0 13 14',
    data: '<path pid="0" d="M.5 5.425L6.5 0l6 5.425v2.61L7.561 3.57 7.547 14H5.453L5.439 3.57.5 8.035v-2.61z" _fill="#FF7D01"/>'
  }
})

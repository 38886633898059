/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wa': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.992 15.863h.003c4.371-.002 7.927-3.558 7.928-7.929a7.88 7.88 0 00-2.32-5.609A7.877 7.877 0 007.995 0C3.625 0 .068 3.557.066 7.928c0 1.397.365 2.761 1.059 3.964L0 16l4.203-1.102a7.921 7.921 0 003.789.965zm3.726-6.222c.138.067.232.112.271.179.05.082.05.479-.115.942-.165.462-.957.885-1.337.942a2.715 2.715 0 01-1.248-.079 11.387 11.387 0 01-1.13-.417c-1.856-.802-3.112-2.602-3.349-2.942l-.034-.05-.002-.002c-.105-.14-.807-1.077-.807-2.047 0-.912.448-1.39.654-1.61l.039-.043a.728.728 0 01.528-.247c.132 0 .264 0 .38.007h.044c.116 0 .26-.001.402.34l.218.53c.17.416.36.874.392.94.05.1.083.215.017.348l-.028.056c-.05.101-.086.176-.17.274l-.102.122a3.279 3.279 0 01-.195.225c-.1.099-.203.206-.087.405.115.198.513.847 1.102 1.372.633.565 1.183.803 1.462.924.055.024.099.043.131.06.198.098.314.082.43-.05.115-.133.494-.579.626-.777.132-.198.265-.165.446-.1.182.067 1.156.546 1.354.645l.108.053z" _fill="#FF7D01"/>'
  }
})

export default {
    //in _variables.scss, config.js and plugins/vuetify.js
    WIDTH_XS: 320,
    WIDTH_XSM: 744,
    WIDTH_SM: 1024,
    WIDTH_MD: 1280,
    WIDTH_LG: 1280,
    WIDTH_XL: 1920,

    DEFAULT_NEWS_COUNT: 6,
    MORE_NEWS_COUNT: 6,

    MAPBOX_ACCESS_TOKEN: "pk.eyJ1IjoicGxheW5leHQiLCJhIjoiY2xjbnhubHp0MWRzZTN5bXExcDJ4YjI1OCJ9.LWrmczx_ZnkUKjFtIUhv9w",
    MAPBOX_STYLE: "mapbox://styles/playnext/cjqr7cp2i36ip2rqilaiuxmq3",

    YANDEX_API_KEY: "6b9f896a-b565-473b-8609-e717e3ed8429",

    API_EXCEPTION_NOT_FOUND: "NOT_FOUND"
}
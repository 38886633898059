/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tg': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<g clip-path="url(#clip0_1397_145043)"><path pid="0" d="M8.924 16a8 8 0 100-16 8 8 0 000 16z" _fill="#FF7D01"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M4.545 7.915a380.734 380.734 0 014.665-2.01c2.222-.923 2.683-1.084 2.984-1.089a.526.526 0 01.31.093c.081.066.103.154.114.217.01.062.024.204.013.315-.12 1.265-.64 4.334-.906 5.751-.112.6-.333.8-.547.82-.464.043-.817-.307-1.267-.602-.704-.461-1.102-.748-1.785-1.199-.79-.52-.278-.806.172-1.274.118-.122 2.165-1.984 2.204-2.153.005-.021.01-.1-.037-.142-.047-.041-.116-.027-.165-.016-.071.016-1.196.76-3.375 2.23-.32.22-.608.327-.868.321-.285-.006-.835-.161-1.243-.294-.501-.163-.9-.249-.865-.526.018-.144.217-.29.596-.442z" _fill="#0C303C"/></g><defs><clipPath id="clip0_1397_145043"><path pid="2" _fill="#fff" transform="translate(.924)" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})

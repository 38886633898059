/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ap1': {
    width: 1280,
    height: 770,
    viewBox: '0 0 1280 770',
    data: '<g clip-path="url(#clip0_56_57526)"><mask id="svgicon_ap1_a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1280" height="770"><path pid="0" _fill="#F2F6F7" d="M0 0h1280v770H0z"/></mask><g mask="url(#svgicon_ap1_a)" _fill="#FF7D01"><path pid="1" d="M-598.158 1643.84l-123.696-145.41-358.056-767.442 282.086-149.181 497.014 909.273-5.608 16.56-291.74 136.2zM1979.31 716.83l-86.84-2.161L380.828 1608.99l-3.4 12.7L453.877 1751l157.648 282.88 111.895 18.02 175.577-19.33 1765.893-769.91 4-38.56-689.58-507.27zM99.934-216.196L280.19-469.049l927.48 639.875v288.925L166.871 1272.09l-603.528-788.663 104.9-209.939 431.69-489.684zM104.171 1316.27l2.361-9.69-636.858-901.713-222.786 147.407 376.368 688.616 123.554 227.3 19.404 5.44 337.957-157.36z"/></g></g><defs><clipPath id="clip0_56_57526"><path pid="2" _fill="#fff" d="M0 0h1280v770H0z"/></clipPath></defs>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burger': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<rect pid="0" width="32" height="32" rx="16" _fill="#FF7D01"/><path pid="1" _fill="#fff" d="M8 12h16v2H8zM8 18h16v2H8z"/>'
  }
})

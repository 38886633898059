/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'btn-form': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<rect pid="0" x=".66" y=".58" width="24.84" height="24.84" rx="12.42" _fill="#FF7D01"/><path pid="1" d="M10.02 7.58h8.48v8.48l-2.04 2.04v-6.98l-7.32 7.3-1.48-1.48 7.3-7.32H7.98l2.04-2.04z" _fill="#fff"/>'
  }
})

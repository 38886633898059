<template>
	<v-app>
		<transition name="route" mode="out-in">
			<router-view class="d-block"/>
		</transition>

<!--		<div class="stub c2 d-flex d-xl-none justify-center align-center text-center">
			<v-container>
				<h3>Извините, но предпросмотр возможен только<br />на разрешении 1920 пкс и больше.</h3>
			</v-container>
		</div>-->
	</v-app>
</template>

<script>

	export default {
		name: 'App',

		data: () => ({
			//
		}),
		/*watch: {
			isPopup(val) {
				if ( val ) {
					setTimeout(()=>{
						document.body.classList.add("fixed");
					},400);
				}
				else document.body.classList.remove("fixed");
			}
		},*/
		computed: {
			isPopup() {
				return this.$store.state.isPopupFormShown
					|| this.$store.state.isPopupGalleryShown;
			}
		},
		methods: {
			onResize() {
				//console.log("App onResize");

				this.$store.commit('updateWindowWidth');
				this.$store.commit('updateWindowHeight');

				// на iOS чтобы попадало во весь экран на телефоне 100vh:
				//нужно добавить css переменную vh в main.scss:
				//:root {
				//--vh: 1vh;
				//}
				//после вместо vh использовать var(--vh, 1vh)
				//например всето 100vh = calc(var(--vh, 1vh)*100)
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			},
		},
		mounted() {
			window.addEventListener("resize", this.onResize);
			this.onResize();
		},
		destroyed() {
			window.removeEventListener("resize", this.onResize);
		}
	};
</script>

<style lang="scss">
	#app {
		//overflow: hidden;
		min-width: $xs;
	}
</style>
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow2': {
    width: 10,
    height: 8,
    viewBox: '0 0 10 8',
    data: '<path pid="0" d="M0 2.794l5 4.65 5-4.65V.557L5.884 4.384 5 5l-.884-.616L0 .557v2.237z" _fill="#fff"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'a2': {
    width: 256,
    height: 256,
    viewBox: '0 0 256 256',
    data: '<path pid="0" d="M138 0h-20L0 80v176h256V80L138 0z" _fill="#0C303C" bx:origin="0 0"/>'
  }
})

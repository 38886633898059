import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
    {
        path: '/', component: () => import("@/components/layout/layout-main"),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import("@/views/Home"),
                meta: {api: "HomeController", withSubscription: true}
            },
            {
                path: '/products',
                name: 'products',
                component: () => import("@/views/UnderConstruction"),
                //meta: {api: "HomeController"}
            },
            {
                path: '/products/built-to-suit',
                name: 'products-suit',
                component: () => import("@/views/ProductsSuit"),
                meta: {api: "PageController", params:{code:'Built-to-Suit'}}
            },
            {
                path: '/products/light-industrial',
                name: 'products-industrial',
                component: () => import("@/views/ProductsIndustrial"),
                meta: {api: "PageController", params:{code:'Light-Industrial'}}
            },
            {
                path: '/products/lands',
                name: 'products-land',
                component: () => import("@/views/ProductsLand"),
                meta: {api: "LandsController", params:{code:'Lands'}}
            },

            {
                path: '/for-clients',
                name: 'for-clients',
                component: () => import("@/views/UnderConstruction"),
                //meta: {api: "HomeController"}
            },
            {
                path: '/for-clients/infrastructure',
                name: 'infrastructure',
                component: () => import("@/views/Infrastructure"),
                meta: {api: "InfrastructuresController", params:{code:'Infastructure'}}
            },
            {
                path: '/for-clients/advantages',
                name: 'advantages',
                component: () => import("@/views/Advantages"),
                meta: {api: "PageController", isHeaderInverted: true, headerPlusInvertedColor: "#efe6dd", params:{code:'park'}}
            },
            {
                path: '/for-clients/government-support',
                name: 'government-support',
                component: () => import("@/views/GovernmentSupport"),
                meta: {api: "GovernmentSupportController", params:{code:'government-support'}}
            },
            {
                path: '/for-clients/projects',
                name: 'projects',
                component: () => import("@/views/Projects"),
                // здесь withoutBlog для победы над тупым webkit, который показывает anim-arrows над секциями с более высоким z-index
                meta: {api: "ProjectsController", params:{code:'projects'}, isHeaderInvertedMD: true, headerPlusInvertedColor: "#efe6dd", withoutBlog: true}
            },
            {
                path: '/for-clients/projects/:code',
                name: 'project-item',
                component: () => import("@/views/ProjectItem"),
                props: true,
                meta: {api: "ProjectController", isHeaderInverted: true, headerPlusInvertedColor: "#efe6dd"}
            },
            {
                path: '/for-clients/faq',
                name: 'faq',
                component: () => import("@/views/FAQ"),
                meta: {api: "FAQsController", params:{code:'faq'},}
            },
            {
                path: '/residents',
                name: 'residents',
                component: () => import("@/views/Residents"),
                meta: {api: "ResidentsController", params:{code:'residents'}, isHeaderInverted: true}
            },
            {
                path: '/residents/:index',
                name: 'residents-item',
                component: () => import("@/views/Residents"),
                props: true,
                meta: {api: "ResidentsController", params:{code:'residents'}, isHeaderInverted: true}
            },
            {
                path: '/blog',
                name: 'blog',
                component: () => import("@/views/Blog"),
                meta: {api: "BlogsController", params:{code:'blog'} /*query: {section: 'section'}*/}
            },
            {
                path: '/blog/:code',
                name: 'blog-item',
                component: () => import("@/views/BlogItem"),
                meta: {api: "BlogController", withSubscription: true}
            },
            {
                path: '/news/:code',
                name: 'news-item',
                component: () => import("@/views/BlogItem"), // Используем тот же компонент, что и для блога
                meta: {api: "NewOneController", withSubscription: true}
            },
            {
                path: '/gallery',
                name: 'gallery',
                component: () => import("@/views/Gallery"),
                meta: {api: "GalleryController", params:{code:'gallery'},}
            },

            {
                path: '/services',
                name: 'services',
                component: () => import("@/views/Services"),
                meta: {api: "ServicesController", params:{code:'services'},}
            },
            {
                path: '/for-residents',
                name: 'for-residents',
                component: () => import("@/views/ForResidents"),
                meta: {api: "InfosController", params:{code:'for-residents'}, withoutBlog: true}
            },
/*            {
                path: '/media',
                name: 'media',
                component: () => import("@/views/UnderConstruction"),
                //meta: {api: "HomeController"}
            },*/
            {
                path: '/about',
                name: 'about',
                component: () => import("@/views/About"),
                meta: {api: "AboutController", params:{code:'about'}, withoutBlog: true, isHeaderInvertedMD: true, headerPlusInvertedColor: "#efe6dd", withDarkFooter: true}
            },
            {
                path: '/career',
                name: 'career',
                component: () => import("@/views/Career"),
                meta: {api: "VacanciesController",params:{code:'vacancies'}, withoutBlog: true, isHeaderInvertedMD: false,  withDarkFooter: true}
            },
/*            {
                path: '/management',
                name: 'management',
                component: () => import("@/views/UnderConstruction"),
                //meta: {api: "HomeController"}
            },*/
            {
                path: '/policy',
                name: 'policy',
                component: () => import("@/views/Policy"),
                meta: {api: "CommonController", withoutBlog: true}
            },
            {
                path: '/error404',
                name: 'error404-page',
                component: () => import("@/views/Error404"),
                meta: {withoutBlog: true, api: "CommonController"}
            },
            {
                path: '/error500',
                name: 'error500',
                component: () => import("@/views/Error500"),
                meta: {withoutBlog: true, api: "CommonController"}

            },
            {
                path: '/under-construction',
                name: 'under',
                component: () => import("@/views/UnderConstruction"),
            },
            {
                path: '*',
                name: 'error404',
                component: () => import("@/views/Error404"),
                meta: {withoutBlog: true, api: "CommonController"},
                //beforeEnter() { window.location.href = "/error404" },
            },

        ]
    }
];
export default routes;
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import routes from '@/router/routes';
import {mergeObjects} from "@/utils/utils";
import Config from "@/config";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

const scrollTo = (hash = null, offset = 0, behavior = 'smooth') => {
	let top = 0;

	if (typeof hash === "string") {
		hash = hash.match(/^\#.+/) ? hash.substring(1) : hash;
		const el = document.getElementById(hash);
		//console.log('Rect:', el.getBoundingClientRect());
		if (el) top = el.getBoundingClientRect().top + window.scrollY + offset;
		else {
			console.warn("Scroll to element not found: " + hash);
			return;
		}
	} else if (typeof hash === "number") top = hash;

	window.scrollTo({
		behavior,
		left: 0,
		top
	});
};

window.isPopState = false;
window.addEventListener('popstate', () => {
	window.isPopState = true;
});

let lastPagePos;
router.beforeEach((to, from, next) => {
	store.state.isLoading = true;

	// сохранение позиции страницы
	if (!window.isPopState) {
		lastPagePos = document.documentElement.scrollTop || document.body.scrollTop;
		console.log("lastPagePos BEFORE ROUTING", lastPagePos);
	}

	// если мы навигируем только по hash-ам на том же роуте - просто скроллим
	/*if (from.meta?.api === to.meta?.api && to.meta?.hash) {
		scrollTo(to.meta?.hash);
		//next();
		//router.replace(to);
		store.state.isBurgerMenuShown = false;
		return;
	}*/

	// ждем затухания страницы (см. layout-main.loading)
	setTimeout(() => {
		// все затухло, загружаем данные

		// загружаем данные текущего роута (кэшируемые), в т.ч. common данные:
		if (to.meta?.api) {
			//store.state.isLoading = true;

			let params = mergeObjects({}, {
				action: to.meta.api,
				method: to.meta.method,
				params: to.params
			}, to.meta);
			store.dispatch('fetchPage', params)
				.then(() => {
					next();
				})
				.catch((err) => {
					console.error("API error: ", err);
					if (err.code === Config.API_EXCEPTION_NOT_FOUND) {
						router.replace({name: 'error404'});
					} else {
						router.replace({name: 'error500'});
					}
					//next();
				});
		} else {
			next();
		}

	}, 300);
});

router.afterEach((to, from) => {

	if (store.getters['isWindowMD']) store.state.isHeaderInverted = to.meta?.isHeaderInverted || to.meta?.isHeaderInvertedMD;
	else store.state.isHeaderInverted = to.meta?.isHeaderInverted;

	// небольшая пауза для построения дома
	setTimeout(() => {
		if (from?.path && to?.path !== from.path) {
			if (window.isPopState && lastPagePos) {
				// we are moving back - restore page position
				console.log("AFTER BACK ROUNTING - scrolling to", lastPagePos);
				scrollTo(lastPagePos, 0, 'instant');
				lastPagePos = null;
			} else {

				if (to.meta?.hash) {
					// yet more pause to allow dom-model to appear
					setTimeout(() => scrollTo(to.meta?.hash, 0, 'smooth'), 500);
				} else {
					scrollTo(null, 0, 'instant');
				}
			}
			window.isPopState = false;
		}

		store.state.isLoading = false;
		store.state.isBurgerMenuShown = false;
	}, 150);
});

export default router;

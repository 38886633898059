/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vk': {
    width: 17,
    height: 10,
    viewBox: '0 0 17 10',
    data: '<path pid="0" d="M13.701 5.685c-.259-.326-.185-.472 0-.764.003-.003 2.139-2.954 2.359-3.955h.001c.11-.365 0-.633-.529-.633h-1.749c-.445 0-.65.23-.76.487 0 0-.891 2.132-2.152 3.514-.406.4-.594.528-.816.528-.11 0-.28-.128-.28-.493V.966c0-.438-.124-.633-.493-.633h-2.75c-.28 0-.446.204-.446.394 0 .415.63.51.695 1.677v2.531c0 .555-.1.657-.324.657-.594 0-2.038-2.14-2.893-4.591-.173-.475-.341-.667-.79-.667h-1.75c-.5 0-.6.23-.6.487 0 .455.595 2.715 2.765 5.702 1.447 2.039 3.484 3.144 5.338 3.144 1.114 0 1.25-.246 1.25-.668 0-1.948-.1-2.132.457-2.132.259 0 .704.128 1.744 1.112 1.189 1.166 1.384 1.688 2.05 1.688h1.749c.499 0 .751-.246.606-.73-.333-1.018-2.58-3.112-2.682-3.252z" _fill="#FF7D01"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm1-17V8h-2v7H8v2h7v7h2v-7h7v-2h-7z" _fill="#FF7D01"/>'
  }
})

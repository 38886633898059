/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dot': {
    width: 10,
    height: 11,
    viewBox: '0 0 10 11',
    data: '<path pid="0" d="M5.076 10.25c-1.317 0-2.457-.469-3.42-1.406C.719 7.881.25 6.741.25 5.424c0-1.292.469-2.394 1.406-3.306C2.593 1.206 3.733.75 5.076.75c1.292 0 2.394.456 3.306 1.368.912.912 1.368 2.014 1.368 3.306 0 1.343-.456 2.483-1.368 3.42-.912.937-2.014 1.406-3.306 1.406z" _fill="#fff"/>'
  }
})

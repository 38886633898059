/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'yt': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<circle pid="0" cx="8" cy="8" r="8" _fill="#FF7D01"/><path pid="1" d="M11.817 6C12 6.711 12 8.2 12 8.2s0 1.488-.183 2.2a1.124 1.124 0 01-.775.81C10.358 11.4 8 11.4 8 11.4s-2.357 0-3.042-.19a1.124 1.124 0 01-.775-.81C4 9.689 4 8.2 4 8.2s0-1.488.183-2.2c.101-.395.399-.705.775-.81C5.643 5 8 5 8 5s2.358 0 3.042.19c.378.107.675.416.775.81zM7.2 9.251a.2.2 0 00.3.173l1.804-1.052a.2.2 0 000-.346L7.5 6.975a.2.2 0 00-.301.173v2.104z" _fill="#0C303C"/>'
  }
})

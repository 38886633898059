/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 12,
    height: 14,
    viewBox: '0 0 12 14',
    data: '<path pid="0" d="M.5.938L11 7 .5 13.062V.938z"/>'
  }
})

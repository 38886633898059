/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scroll-xs': {
    width: 23,
    height: 36,
    viewBox: '0 0 23 36',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.5 11v14c0 5.523-4.477 10-10 10s-10-4.477-10-10V11c0-5.523 4.477-10 10-10s10 4.477 10 10zm-21 0c0-6.075 4.925-11 11-11s11 4.925 11 11v14c0 6.075-4.925 11-11 11S.5 31.075.5 25V11zm11 1a1 1 0 00-1 1v10a1 1 0 102 0V13a1 1 0 00-1-1z" _fill="#2A3336"/>'
  }
})

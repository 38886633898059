/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 14,
    height: 12,
    viewBox: '0 0 14 12',
    data: '<path pid="0" d="M5.425 0L0 6l5.425 6h2.61L3.57 7.061 14 7.047V4.953L3.57 4.939 8.035 0h-2.61z" _fill="#000"/>'
  }
})

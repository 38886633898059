/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play2': {
    width: 12,
    height: 15,
    viewBox: '0 0 12 15',
    data: '<path pid="0" d="M.5 1.438L11 7.5.5 13.562V1.438z" _fill="#AABEC5" _stroke="#AABEC5"/>'
  }
})

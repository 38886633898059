/* eslint-disable */
require('./a1')
require('./a2')
require('./ap1')
require('./arrow-left')
require('./arrow-right')
require('./arrow')
require('./arrow2')
require('./btn-form')
require('./burger')
require('./checkbox-off')
require('./checkbox-on')
require('./dot')
require('./folder')
require('./fullscreen')
require('./fullscreen2')
require('./logo-inverted')
require('./logo')
require('./pause')
require('./play')
require('./play2')
require('./play3')
require('./plus')
require('./residents-arrow')
require('./scroll-xs')
require('./scroll')
require('./tg')
require('./vk')
require('./wa')
require('./yt')

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'a1': {
    width: 256,
    height: 256,
    viewBox: '0 0 256 256',
    data: '<path pid="0" d="M256 118L80 0H0v256h80l176-118v-20z" _fill="#0C303C" data-bx-origin="0 0"/>'
  }
})

//import config from '../config'

let state = {

	response: {},	// объект ответа от БЕ в чистом виде
	session: {},	// данные текущей сессии
	common: {},			// общие для всех страниц
	page: {},			// индивидуальные для каждой страницы
	currentPage: {},	// текущая страница
	isLoading: false,

	// реактивные размеры экрана
	windowWidth: window.innerWidth,
	windowHeight: window.innerHeight,

	// глобальные параметр хедера
	isHeaderInverted: false,
	isHeaderScrolledAllowed: true,	// разрешить применять отскролленое состояние хедера
	isBurgerMenuShown: false,
	isPopupFormShown: false,
	currentPopupFormPerson: null, // текущий человек на попапе
	isPopupGalleryShown: false,
	headerHeight: 64,

	menuItems: [
		{
			name: "Продукты", to: {name: "products"},
			children: [
				{name: "Built to suit", to: {name: "products-suit"}},
				{name: "Light Industrial", to: {name: "products-industrial"}},
				{name: "Земельные участки", to: {name: "products-land"}},
			]
		},
		{
			name: "Клиенту", to: {name: "for-clients"},
			children: [
				{name: "Инфраструктура", to: {name: "infrastructure"}},
				{name: "Преимущества парка", to: {name: "advantages"}},
				{name: "Помощь в получении господдержки", to: {name: "government-support"}},
				{name: "Витрина проектов", to: {name: "projects"}},
				{name: "FAQ", to: {name: "faq"}},
			]
		},
		{
			name: "Резиденты", to: {name: "residents"},
		},
		{
			name: "Управляющая компания", to: {name: "management"},
			children: [
				{name: "Услуги управляющей компании", to: {name: "services"}},
				{name: "Информация резиденту", to: {name: "for-residents"}},
			]
		},
		{
			name: "О компании", to: {name: "about"},
			/*children: [
				{name: "Наша миссия", to: {name: "under"}},
				{name: "История компании", to: {name: "under"}},
				{name: "Сотрудники", to: {name: "under"}},
				{name: "Партнеры", to: {name: "under"}},
				{name: "Как нас найти", to: {name: "under"}},
			]*/
		},
		{
			name: "Вакансии", to: {name: "career"},
		},
		{
			name: "Медиа", to: {name: "media"},
			children: [
				{name: "Блог ПРО-БИЗНЕС-ПАРК", to: {name: "blog"}},
				{name: "Галерея", to: {name: "gallery"}},
			]
		},
	],

	infrastructure: [
		{
			isActive: true,
			label: "Электроснабжение",
			html: "<p>Электрическая подстанция 110/10 кВ, мощностью 15 МВт, 2 распределительных пункта (РП) и 10 трансформаторных подстанций (КТПН). Шкафы вводных устройств (ШВУ) на каждом участке обеспечат временное подключение на период строительства объекта.</p>",
			img: require("@/assets/infrastructure/i1.jpg"),
			map: require("@/assets/home/home-map/electricity.svg"),
		},
		{
			isActive: false,
			label: "Газоснабжение",
			html: "<p>Распределительный газопровод среднего давления (0,3 МПа). Точки подключения не далее 25 метров от границы каждого земельного участка. Общий лимит объемов газоснабжения - 6800 м3/час.</p>",
			img: require("@/assets/infrastructure/i2.jpg"),
			map: require("@/assets/home/home-map/gaz.svg"),
		},
		{
			isActive: false,
			label: "Водоснабжение",
			html: "<p>Система централизованного водоснабжения. Собственный водозаборный узел (ВЗУ) мощностью 1745 м3/сут, 2 скважины, 4 накопительных резервуара. Очистка воды до качества питьевой.</p>",
			img: require("@/assets/infrastructure/i3.jpg"),
			map: require("@/assets/home/home-map/water.svg"),
		},
		{
			isActive: false,
			label: "Хозяйственно-бытовая канализация",
			html: "<p>Действующая централизованная хозяйственно-бытовая канализация с отводом сточных вод в КНС и последующим сбросом в городские очистные сооружения. Объекты подключает  МУП «Водоканал».</p>",
			img: require("@/assets/infrastructure/i4.jpg"),
			map: require("@/assets/home/home-map/canalization.svg"),
		},
		{
			isActive: false,
			label: "Ливневая канализация",
			html: "<p>Централизованная дождевая канализация закрытого типа с локальными очистными сооружениями (ЛОС). Отведение поверхностного стока осуществляется с автодорог и участков, а значит строительство собственных ЛОС вам не потребуется.</p>",
			img: require("@/assets/infrastructure/i5.jpg"),
			map: require("@/assets/home/home-map/rains.svg"),
		},
		{
			isActive: false,
			label: "Автодороги",
			html: "<p>Прямой подъезд к каждому земельному участку от Полевского тракта. Ширина проезжей части подходит для движения как легкового, так и большегрузного транспорта. Элементы благоустройства улично-дорожной сети включают тротуары, газоны и сети наружного освещения вдоль всех улиц парка.</p>",
			img: require("@/assets/infrastructure/i7.jpg"),
			map: require("@/assets/home/home-map/roads4.svg"),
		},
		{
			isActive: true,
			label: "Сети связи",
			html: "<p>На территории проведены волоконно-оптические линии связи (ВОЛС), оператором связи выступает «Била́йн». Это значит, что вы получите высокоскоростной доступ в Интернет, все виды телефонной связи и организацию корпоративных сетей передачи данных.</p>",
			img: require("@/assets/infrastructure/i6.jpg"),
			map: require("@/assets/home/home-map/radio.png"),
		},
	],

	currentLanguageCode: "EN",
	languages: [
		{
			code: "EN",
			name: "English language",
		},
		{
			code: "RU",
			name: "Русский",
		}
	],

};

export default state;

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<rect pid="0" width="32" height="32" rx="16" _fill="#8AB0BB"/><path pid="1" d="M14.425 10L9 16l5.425 6h2.61l-4.465-4.939L23 17.047v-2.094l-10.43-.014L17.035 10h-2.61z" _fill="#fff"/>'
  }
})

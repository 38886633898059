/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scroll': {
    width: 35,
    height: 50,
    viewBox: '0 0 35 50',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.5 17v16c0 8.837-7.163 16-16 16s-16-7.163-16-16V17c0-8.837 7.163-16 16-16s16 7.163 16 16zm-33 0c0-9.389 7.611-17 17-17s17 7.611 17 17v16c0 9.389-7.611 17-17 17S.5 42.389.5 33V17zm17-1a2 2 0 00-2 2v12a2 2 0 104 0V18a2 2 0 00-2-2z" _fill="#2A3336"/>'
  }
})

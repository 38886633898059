/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<rect pid="0" y=".5" width="24" height="24" rx="12" _fill="#FF7D01"/><path pid="1" d="M13.575 6.5l5.425 6-5.425 6h-2.61l4.465-4.939L5 13.547v-2.094l10.43-.014L10.965 6.5h2.61z" _fill="#fff"/>'
  }
})
